<!-- Contact-submission template -BEGIN -->
<form
  [formGroup]="formRef"
  (ngSubmit)="onSubmit($event)">

  <div>
    <input formControlName="emailaddress" type="text" required="required"
           [placeholder]="'login-popup.input.email' | label"
           class="amg-form-field"/>
  </div>
  <div>
    <input
      formControlName="password" type="password" required="required"
      [placeholder]="'login-popup.input.password' | label"
      class="amg-form-field">
    <!-- Error output template -BEGIN -->
    <div *ngIf="formSubmissionMessageResponse && formSubmissionMessageResponse.error"
         class="amg-form-error" [textContent]="formSubmissionMessageResponse.error | label"></div>
    <!-- Error output template -END -->
  </div>
  <div>
    <button type="submit" [textContent]="'login' | label"></button>
  </div>
</form>
<!-- Contact-submission template -END -->
