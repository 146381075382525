import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Button } from '../../shared/data/wordpress-data.model';
import { UserAuthService } from '../../user-auth.service';
import { CloudPayService } from '../../cloud-pay.service';

@Component({
  selector: 'amg-login-btn',
  templateUrl: './login-btn.component.html',
  encapsulation: ViewEncapsulation.None
})
export class LoginBtnComponent implements OnInit {
  @Input()
  loginBtn: Button;

  @Input()
  showUserAccountDropdown: boolean;

  public loginButtonClicked: boolean;

  public customerProfilePicture: string;

  show = false;
  constructor(
    private userAuthService: UserAuthService,
    private cloudPayService: CloudPayService,
  ) {
    this.loginButtonClicked = false;
    this.customerProfilePicture = '';
  }

  ngOnInit() {
    this.userAuthService.getAccountInfo()
      .subscribe((res) => {
        this.customerProfilePicture = res?.profile?.photoURL || '';
      });

    this.cloudPayService.cloudPayState()
      .subscribe((res) => {
        this.show = (!res.isLoggedIn);
      });
  }

  public doLogin() {
    this.loginButtonClicked = true;
    this.userAuthService.doLogin();
  }

}
