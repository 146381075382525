import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {ArchivePage} from '../types/wordpress/pages/archive-page.type';


import {WpCommonFields} from '../types/wordpress/wp-common-fields.type';

import {environment} from '../../../environments/environment';
import {Labels} from '../types/labels.type';
import {configs} from '../configs';
import {PlayerPage} from '../types/wordpress/pages/player-page.type';

@Injectable({
  providedIn: 'root'
})
export class WordpressService {
  private cache: { [url: string]: ReplaySubject<any> };
  private cacheLabels: ReplaySubject<Labels>;
  private cacheTexts: ReplaySubject<Labels>;

  private static generatePageUrl(path: string): string {
    return `${environment.wordpress.api}${path}`;
  }

  private static getPageUrl(key: string): string {
    return `${environment.wordpress.api}${configs.wordpress.pages[key]}`;
  }

  private static getCommonDataUrl(): string {
    return `${environment.wordpress.api}${configs.wordpress.commonData}`;
  }

  constructor(private http: HttpClient) {
    this.cache = {};
    this.cacheLabels = null;
    this.cacheTexts = null;
  }

  /**
   * Grabs the data from the memory cache if available, else it will trigger a call and retrieves the data.
   * @param pagePath WP page path
   */
  public getPageData<D>(pagePath: string): Observable<D> {
    // console.log('GetPageData: ', pagePath);
    return this.getData<D>(WordpressService.generatePageUrl(pagePath));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getCommonData(): Observable<WpCommonFields> {
    return this.getData<WpCommonFields>(WordpressService.getCommonDataUrl());
  }

  public getLabels(): Observable<Labels> {
    if (!this.cacheLabels) {

      this.cacheLabels = new ReplaySubject<Labels>(1);

      this.getData<WpCommonFields>(WordpressService.getCommonDataUrl()).subscribe((value: WpCommonFields) => {

        if (value && value.labels) {

          const labels: Labels = {};

          const lang = (window as any).appLang;

          value.labels.forEach((element) => {

            labels[element.key] = element[lang];
          });

          this.cacheLabels.next(labels);
        }
      });
    }

    return this.cacheLabels.asObservable();
  }

  public getTexts(): Observable<Labels> {
    if (!this.cacheTexts) {

      this.cacheTexts = new ReplaySubject<Labels>(1);

      this.getData<WpCommonFields>(WordpressService.getCommonDataUrl()).subscribe((value: WpCommonFields) => {

        if (value && value.texts) {

          const texts: Labels = {};

          const lang = (window as any).appLang;

          value.texts.forEach((element) => {

            texts[element.key] = element[lang];
          });

          this.cacheTexts.next(texts);
        }
      });
    }

    return this.cacheTexts.asObservable();
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getArchivePage(): Observable<ArchivePage> {
    return this.getData<ArchivePage>(WordpressService.getPageUrl('archive'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getPlayerCarouselPage(page): Observable<PlayerPage> {
    return this.getData<PlayerPage>(WordpressService.getPageUrl(page));
  }

  private getData<T>(url: string): Observable<T> {
    // console.log('get data: ', url);

    if (!this.cache[url]) {
      this.cache[url] = new ReplaySubject<T>(1);

      this.http.get(url).subscribe(value => {
        this.cache[url].next(value);
      });
    }

    return this.cache[url].asObservable();
  }
}
