import {environment} from '../../environments/environment';

export const configs = {

  playlistPageSize: 24,
  generalContentPageSize: 24,
  carouselItemSize: 20,
  streamPayments: {
    baseurl: `https://${environment.cloudPayDomain}`,
    urls: {
      myAccount: '/account',
      reset: '/account/reset',
      registration: '/account/freeregistration',
      signOut: '/account/signout'
    },
    // name: 'streamPayments',
    // src: `https://${environment.cloudPayDomain}/_resx/js/stream-payments.js`,
    // dependencies: [{name: 'jQuery', src: `https://${environment.cloudPayDomain}/Assets/Account/js/jquery.min.1.9.1.js`}],
  },
  gigya: {
    name: 'gigya',
    src: `https://cdns.eu1.gigya.com/js/gigya.js?apikey=${environment.gigyaKey}`,
    jsConfigObject: {
      storageDomainOverride: environment.gigyaStorageDomainOverride,
    },
  },
  wordpress: {
    pages: {
      onDemand: '/section?slug=on-demand',
      tv: '/section?slug=tv',
      live: '/section?slug=live',
      archive: '/section?slug=archive',
      support: '/section?slug=support-page',
    },
    commonData: '/common_field?slug=common-fields'
  },
};
