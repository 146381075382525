<ng-container *ngIf="subscriptionPackages">
  <amg-subscription-package *ngFor="let planOption of subscriptionPackages"
                            [package]="planOption"
                            ></amg-subscription-package>
</ng-container>

<div *ngIf="!areThereAnySubscriptionPackages()" class="amg-subscription-packages">
  <div class="amg-subscription-package-inner">
    <div class="amg-title" [textContent]="'subscribe-page.no-packages.title' | label"></div>
    <div class="amg-body" [textContent]="'subscribe-page.no-packages.text' | label"></div>
  </div>
</div>
