<footer>
  <div class="links-container">
    <div><a routerLink="on-demand" routerLinkActive="selected">On Demand</a></div>
    <div><a routerLink="live" routerLinkActive="selected">Live</a></div>
    <div><a routerLink="faq" routerLinkActive="selected">FAQs</a></div>
  </div>

  <div class="footer-sponsors">
    <ng-container *ngIf="footerData && footerData.sponsors">
      <div *ngFor="let footerSponsor of footerData.sponsors;"
           class="footer-sponsors__row">
        <div *ngIf="footerSponsor && footerSponsor?.title?.length"
             class="footer-sponsors__row-title">{{footerSponsor.title}}</div>
        <div *ngIf="footerSponsor && footerSponsor?.item"
             class="footer-sponsors__row-items">
          <ng-container *ngFor="let item of footerSponsor?.item;">
            <div class="item"
                 *ngIf="item?.icon?.sizes?.thumbnail">
              <a target="_blank"
                 [href]="item.link">
                <img [lazyLoad]="item?.icon?.sizes.thumbnail"
                     alt=""/>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="links-container">
    <ng-container *ngIf="footerData && footerData.footer_links">
      <ng-container *ngFor="let footerLink of footerData.footer_links;">
        <div *ngIf="footerLink.external_link">
          <a target="_blank"
             [href]="footerLink.external_link"
             [textContent]="footerLink.label | label"></a>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="copyright"
       [innerText]="'footer.copyright' | label"></div>
</footer>
