import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { UserState } from '../../../modules/user-auth/shared/data/user-auth.model';
import { CloudPayService } from 'src/app/modules/user-auth/cloud-pay.service';
import {CloudPayStatus} from '@streamamg/cloud-pay-lib/src/lib/types/cloud-pay-api.type';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({
    search: new FormControl()
  });

  public userLoggedIn = false;
  public searchOpened = false;
  public menuOpened = false;

  private routerSubscription: Subscription;
  private userAuthSubscription: Subscription;

  private static isInMobileView(pageWidth: number): boolean {
    return pageWidth < 992;
  }

  constructor(private router: Router,
              @Inject(DOCUMENT) private document: Document,
              private renderer: Renderer2,
              private cd: ChangeDetectorRef,
              public cloudPayService: CloudPayService) {
  }

  ngOnInit(): void {
    this.userAuthSubscription = this.cloudPayService.cloudPayState()
      .subscribe((res) => {
        this.userLoggedIn = (res.isLoggedIn);
      });

    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.closeMenu();
        this.closeSearch();
      });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.userAuthSubscription.unsubscribe();
  }


  openMenu() {
    this.menuOpened = true;
    window.scrollTo(0, 0);
    this.renderer.addClass(this.document.body, 'overlay-opened');
  }

  closeMenu() {
    this.menuOpened = false;
    this.renderer.removeClass(this.document.body, 'overlay-opened');
    // trigger refresh html...
    if (this.cd) {
      this.cd.markForCheck();
    }
  }

  toggleSearch(inputElement: HTMLInputElement) {
    if (this.searchOpened) {
      this.closeSearch();
    } else {
      this.openSearch(inputElement);
    }
  }

  openSearch(inputElement: HTMLInputElement) {
    this.searchOpened = true;
    window.scrollTo(0, 0);
    this.renderer.addClass(this.document.body, 'overlay-opened');

    setTimeout(() => {
      inputElement.focus();
    }, 500);
  }

  closeSearch() {
    this.searchOpened = false;

    if (!this.menuOpened) {
      this.renderer.removeClass(this.document.body, 'overlay-opened');
    }
  }

  submitSearch(event: Event, inputElement: HTMLInputElement) {
    event.preventDefault();
    const search = this.form.get('search').value;
    this.closeSearch();

    this.form.setValue({
      search: ''
    });

    inputElement.blur();

    this.router.navigate(['/archive'], {queryParams: {search}});
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = this.document.body.clientWidth;

    if (!HeaderComponent.isInMobileView(width)) {
      if (this.menuOpened && !this.searchOpened) {
        this.closeMenu();
      }

      this.menuOpened = false;
    }
  }
}
