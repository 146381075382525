import {Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { Subscription } from 'rxjs';
import { CloudPayService } from '../cloud-pay.service';

@Component({
  selector: 'amg-user-welcome',
  templateUrl: './user-welcome.component.html',
  encapsulation: ViewEncapsulation.None
})
export class UserWelcomeComponent implements OnInit, OnDestroy {
  @HostBinding('attr.class') class = 'amg-user-welcome';
  @HostBinding('attr.logged-in') loggedInAttr = false;

  @Input() welcomeText: string;

  public data: { name: string; lastName: string; isUserLoggedIn: boolean };

  private userStateSubscription: Subscription;

  constructor(private cloudPayService: CloudPayService) {
    this.data = null;
  }

  ngOnInit() {
    // this.userStateSubscription = this.userAuthService.getUserLoginDetailsObservable().subscribe(value => {
    //   this.data = value;
    //   this.loggedInAttr = (value && value.isUserLoggedIn);
    // });
  }

  ngOnDestroy(): void {
    this.userStateSubscription.unsubscribe();
  }

}
