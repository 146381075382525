<header [class.menu-opened]="menuOpened">
  <div [class.opened]="searchOpened" class="search-container mobile-overlay">
    <div (click)="closeSearch()" class="close-btn"></div>
    <form (ngSubmit)="submitSearch($event, headerSearchBar)" [formGroup]="form">
      <div class="search-content">
        <label for="header-search-bar" [innerText]="'search.title' | label"></label>
        <div class="field-container">
          <input #headerSearchBar formControlName="search" id="header-search-bar"
                 [placeholder]="'search.input.placeholder' | label" type="text"/>
          <input type="submit"/>
        </div>
      </div>
    </form>
  </div>
  <div id="secondary-header">
    <a routerLinkActive="selected" routerLink="faq">FAQs</a>

    <div id="amg-translator-list-select-container">
      <span class="selected-lang" data-value="en">en</span>
      <ul id="amg-translator-list-select">
        <li class="selected" data-value="en">en</li>
        <li data-value="it">it</li>
      </ul>
    </div>
    <div>
      <div (click)="toggleSearch(headerSearchBar)" class="search-icon"></div>
    </div>

    <div (click)="closeMenu()"
         class="close-btn"></div>
  </div>

  <div id="primary-header">
    <div class="logo">
      <a routerLink="/home">
        Roma TV+
      </a>
    </div>

    <div class="mobile-overlay"
         id="menu-container">
      <nav>
        <div><a routerLink="on-demand" routerLinkActive="selected">On Demand</a></div>
        <div><a routerLink="live" routerLinkActive="selected">Live</a></div>
        <div class="faq-link"><a routerLink="faq" routerLinkActive="selected">FAQs</a></div>
      </nav>
    </div>

    <div class="amg-sign-up">
      <amg-user-account-control></amg-user-account-control>

      <ng-container *ngIf="!userLoggedIn">
        <amg-login-btn [loginBtn]="{label: ('login' | label) + ' / ' + ('register' | label)}"></amg-login-btn>
      </ng-container>

    </div>

    <div (click)="openMenu()" class="menu-mobile-icon"></div>

  </div>
</header>
