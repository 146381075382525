import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { WINDOW_PROVIDERS } from './core/providers/window-provider';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/components/header/header.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { CookieNotificationComponent } from './core/components/cookie-notification/cookie-notification.component';
import { SharedModule } from './modules/shared/shared.module';
import {intersectionObserverPreset, LazyLoadImageModule} from 'ng-lazyload-image';
import {UserAuthModule} from './modules/user-auth/user-auth.module';
import {CloudPayModule} from '@streamamg/cloud-pay-lib';
import {environment} from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CookieNotificationComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset // <-- tell LazyLoadImage that you want to use IntersectionObserver
    }),
    UserAuthModule,
    FormsModule,
    // Disable Service worker as we don't want to show 'Add to Home screen' due to Native Mobile applications
    // ServiceWorkerModule.register('ngsw-worker.js',
    //   { enabled: environment.production, registrationStrategy: 'registerImmediately' })
    CloudPayModule.forRoot(
      {
        url: environment.cloudPay.baseUrl,
        language: 'en',
        isSso: true,
      }),
  ],
  providers: [
    WINDOW_PROVIDERS,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
