import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Observable } from 'rxjs';

import { GenericPaymentResponse } from '../shared/data/stream-payments.model';

import { configs } from '../../../core/configs';
import { UserAuthService } from '../user-auth.service';
import { CloudPayService } from '../cloud-pay.service';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'amg-user-account-control',
  templateUrl: './user-account-control.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class UserAccountControlComponent implements OnInit {
  private _userDataObservable: Observable<GenericPaymentResponse>;
  public isLoggedIn: boolean;

  public data: {
    myProfile: string;
    mySubscription: string;
  };

  public customerName: string;
  public customerFullName: string;
  public customerEmail: string;
  public customerProfilePicture: string;

  constructor(
    private cd: ChangeDetectorRef,
    private userAuthService: UserAuthService,
    private cloudPayService: CloudPayService,
  ) {

    this.data = {
      myProfile: environment.gigyaProfile,
      mySubscription: `${configs.streamPayments.baseurl}${configs.streamPayments.urls.myAccount}?page=billing-details`,
    };

    this.customerName = '';
    this.customerFullName = '';
    this.customerEmail = '';
    this.customerProfilePicture = '';
  }

  ngOnInit() {
    this.userAuthService.getAccountInfo()
      .subscribe((res) => {
        this.customerProfilePicture = res?.profile?.photoURL || '';
      });

    this.cloudPayService.cloudPayState()
      .subscribe((res) => {
        this.isLoggedIn = res.isLoggedIn;

        if (this.isLoggedIn) {
          const currentCustomerSession = this.cloudPayService.sessionData?.CurrentCustomerSession;

          this.customerName = currentCustomerSession?.CustomerFirstName || '';
          this.customerFullName = [
            currentCustomerSession?.CustomerFirstName || '',
            currentCustomerSession?.CustomerLastName || '',
          ].join(' ');
          this.customerEmail = currentCustomerSession?.CustomerEmailAddress || '';
        }


        this.cd.markForCheck();
      });
  }
}
