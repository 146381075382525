import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {LoginFormHeader, LoginDataJson, LoginFormBody} from '../../shared/data/stream-payments-scripts.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {configs} from '../../../../core/configs';

@Component({
  selector: 'amg-template-builder',
  templateUrl: './template-builder.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TemplateBuilderComponent implements OnInit {


  @Input() dataObj: LoginDataJson;
  @Input() modalRef: NgbActiveModal;

  logo: LoginFormHeader;
  form: LoginFormBody;

  forgotPasswordLink = `${configs.streamPayments.baseurl}${configs.streamPayments.urls.reset}`;
  registerLink = `${configs.streamPayments.baseurl}${configs.streamPayments.urls.registration}`;

  constructor() {
  }

  ngOnInit() {

    this.form = this.dataObj.form;
  }

}
