<ng-template [ngIf]="dataObj" let-modal>
  <!-- Template -BEGIN -->

  <div class="amg-template">

    <amg-form-header
      [modalRef]="modalRef">
    </amg-form-header>

    <amg-form-builder
      [formData]="form">
    </amg-form-builder>

    <div class="amg-call-to-action">
      <div class="amg-cta-btn-wrapper">
        <a [href]="forgotPasswordLink"
           [textContent]="'login-popup.forgot-password' | label"
           class="amg-cta-btn"></a>
      </div>
    </div>

    <div class="amg-call-to-action">
      <div class="amg-cta-description" [textContent]="'login-popup.no-account' | label"></div>

      <div class="amg-cta-btn-wrapper">
        <a class="amg-cta-btn" [href]="registerLink" [textContent]="'login-popup.register' | label"></a>
      </div>
    </div>

  </div>

  <!-- Template -END -->
</ng-template>
