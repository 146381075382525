export enum StreamPaymentsHooks {
  init = 'init',
  doLoginPost = 'doLoginPost',
  doLogout = 'doLogout',
  getKSession = 'getKSession',
  getSubscriptionPackageList = 'getSubscriptionPackageList'
}

export type StreamPaymentsData = { apisessionid?: string } | LoginSubmissionForm | Init | KSessionRequest | string ;

export interface StreamPayments {
  init?(data: Init): any;

  doLogin?(data: LoginSubmissionForm, callback?: object): GenericPaymentResponse;

  doLoginPost?(data: object, callback?: object): any;

  doLogout?(data: object, callback?: object): any;

  getKSession?(data: KSessionRequest, callback?: object): GenericPaymentResponse;

  getSessionState?(data: null, callback?: object): GenericPaymentResponse;

  getSubscriptionPackageList?(data: null, callback?: object): SubscribtionPackageListResponse;

  isEmailAddressRegistered?(data: object, callback?: object): any;

  isEmailAddressValid?(data: object, callback?: object): any;

  isVoucherCodeValid?(data: object, callback?: object): any;
}

export interface SubscribtionPackageListResponse extends SubscriptionPlan {
  SubscriptionPlanOptions?: SubscriptionPlan[];
}

export interface SubscriptionPlan {
  Id: string;
  Title: string;
  Body: string;
  Style: string;
  Amount: number;
  Currency: string;
  AmountFormatted: string;
  HasFreeTrial: boolean;
  TrialDuration: number;
  PurchaseUrl: any;
  Interval: string;
  Duration: number;
}

export interface Init {
  language?: string | 'en';
}

export interface LoginSubmissionForm {
  emailaddress: GenericInput | string;
  password: GenericInput | string;
}

interface KSessionRequest {
  entryId: string;
}

export interface GenericPaymentResponse {
  AuthenticationToken?: string;
  CurrentCustomerSession?: CurrentCustomerSession;
  CurrentCustomerSessionStatus?: number;
  KSession?: string;
  Status?: number;
  LocationFromIp?: { string };
  ModelErrors?: { string };
  PageUrl?: string;
  UpdatedAt?: string;
  UtcNow?: string;
}

export interface CurrentCustomerSession {
  Id: string;
  CustomerId: string;
  CustomerDeleted: boolean;
  CustomerFirstName: string;
  CustomerLastName: string;
  CustomerEmailAddress: string;
  CustomerSubscriptionCount: number;
  CustomerNonExpiringSubscriptionCount: number;
  CustomerEntitlements: any;
  CustomerFullAccessUntil: any;
  CustomerBillingProfileProvider: any;
  CustomerBillingProfileReference: any;
  CustomerBillingProfileExpiresAt: any;
  CustomerBillingProfileCreatedAt: any;
  CustomerBillingProfileLastFailedAt: any;
}

export interface GenericInput {
  label?: string;
  placeholder?: string;
  values?: string[];
  value?: string;
  input_type?: string;
  required?: boolean;
  internal_reference_name?: string;
}

/*
export interface SubmitBtn extends GenericInput {
  submit_to?: StreamPaymentsHooks | string;
}
*/

