<button (click)="doLogin()"
        *ngIf="loginBtn && show"
        [disabled]="loginButtonClicked"
        [ngClass]="loginBtn.cssClass"
        class="btn amg-login-btn sign-in-button">
  <ng-container *ngIf="customerProfilePicture?.length > 0; then thenBlock else elseBlock"></ng-container>
</button>

<ng-template #thenBlock>
  <img class="profile-pic" [src]="customerProfilePicture" />
</ng-template>
<ng-template #elseBlock>
  <img class="profile-pic" />
</ng-template>
