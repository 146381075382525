import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { SubSink } from 'subsink';

import { CloudPayState } from '@streamamg/cloud-pay-lib/src/lib/types/cloud-pay.type';

import { SubscriptionPackageModel } from '../../shared/data/subscription-package.model';

import { CloudPayService } from '../../cloud-pay.service';
import { UserAuthService } from '../../user-auth.service';

@Component({
  selector: 'amg-subscription-package',
  templateUrl: './subscription-package.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class SubscriptionPackageComponent implements OnInit, OnChanges, OnDestroy {

  @HostBinding('attr.class') class = 'amg-subscription-package';

  @Input() package: SubscriptionPackageModel;

  private userState: CloudPayState;
  public intervalData: { number: string; text: string; };
  private offerText: string;
  public buttonDetails: { class: string; label: string; };
  public period: string;

  private subSink: SubSink;


  constructor(
    private cloudPayService: CloudPayService,
    private userAuthService: UserAuthService,
  ) {
    this.resetData();

    this.subSink = new SubSink();
  }


  private static parseIntervalDate(value: string): { number: string; text: string; } {
    const data = value.match(/[\d.]+|\D+/g);

    return data.length > 1
      ? {
        number: data[0],
        text: data[1],
      }
      : null;
  }

  ngOnInit() {
    this.subSink.sink = this.cloudPayService.cloudPayState()
      .subscribe((userState) => {
        this.userState = userState;
        this.generateData();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.generateData();
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  clickBuy() {
    if (this.userState.isLoggedIn) {
      location.href = this.package.PurchaseUrl;
    } else {
      const href = window.location.href.split('?')[0] + '?purchase_package';

      this.userAuthService.markPackageForPurchase(this.package);
      this.userAuthService.doLogin(href);
    }
  }

  resetData() {
    this.userState = {
      isLoggedIn: false,
      isEntitled: false,
    };

    this.offerText = '';
    this.buttonDetails = {
      class: '',
      label: '',
    };
  }

  generateData() {
    this.generateIntervalData();

    this.generateButtonDetails();
  }

  generateIntervalData() {

    this.intervalData = SubscriptionPackageComponent.parseIntervalDate(this.package.Interval);

    if (this.intervalData.number === '1') {

      this.period = (this.intervalData.number === '1') ? 'single' : 'multiple';
    }
  }

  generateButtonDetails() {
    let buttonClass: string = 'btn-logged-out';
    let buttonLabel: string = 'register';

    if (this.userState.isLoggedIn) {
      buttonClass = 'btn-logged-in';
      buttonLabel = 'subscribe';
    }
    if (this.userState.isEntitled) {
      buttonClass = 'btn-subscription disabled';
      buttonLabel = 'already-registered';
    }

    this.buttonDetails = {
      class: buttonClass,
      label: buttonLabel.replace(/<[^>]*>/g, ''),
    };
  }

}
