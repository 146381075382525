import {OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {Subscription} from 'rxjs';
import {Labels} from '../../../core/types/labels.type';
import {WordpressService} from '../../../core/services/wordpress.service';

@Pipe({
  name: 'label'
})
export class LabelPipe implements PipeTransform, OnDestroy {
  private labelsSubscription: Subscription;
  private labels: Labels;

  constructor(private wordpressService: WordpressService) {

    this.labels = {};

    this.labelsSubscription = this.wordpressService.getLabels().subscribe(data => {

      if (data) {
        this.labels = data;
      }
    });
  }

  transform(value: string, ...args: any[]): string {

    return this.labels[value] ? this.labels[value] : value;
  }

  ngOnDestroy(): void {
    this.labelsSubscription.unsubscribe();
  }
}
