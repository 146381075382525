<div class="amg-icon-placeholder"
     [attr.has-free-trail]="package.HasFreeTrail"></div>

<div class="amg-subscription-package-inner">
  <div *ngIf="package.Title"
       class="amg-title"
       [innerHTML]="package.Title"></div>

  <div *ngIf="package.Body"
       class="amg-body"
       [innerHTML]="package.Body"></div>

  <div *ngIf="intervalData" class="amg-offer-text">
    {{'subscribe.buy.only' | label}} <span class="amg-price"
                                           [textContent]="package.AmountFormatted"></span> {{'subscribe.buy.period.' + period  | label}} {{period == 'multiple' ? intervalData.number : ''}} {{'subscribe.buy.period.' + period + '.' + intervalData.text | label}}

  </div>


  <div
    *ngIf="buttonDetails.label !== ''"
    [class]="'amg-translator-link btn ' + buttonDetails.class"
    [textContent]="buttonDetails.label | label"
    (click)="clickBuy()"
  ></div>
</div>
