import { Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { SubSink } from 'subsink';

import { SubscriptionPackageModel } from '../shared/data/subscription-package.model';
import { CloudPayService } from '../cloud-pay.service';

@Component({
  selector: 'amg-subscription-packages',
  templateUrl: './subscription-packages.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionPackagesComponent implements OnInit, OnDestroy {
  @HostBinding('attr.class') class = 'amg-subscription-packages';

  public subscriptionPackages: SubscriptionPackageModel[];

  private subSink: SubSink;

  constructor(
    private cloudPayService: CloudPayService,
  ) {
    this.subSink = new SubSink();
  }

  ngOnInit() {
    this.subSink.sink = this.cloudPayService.getPackages()
      .subscribe(subscriptionPackages => {
        subscriptionPackages = (subscriptionPackages as any).SubscriptionPlanOptions || subscriptionPackages;
        if (subscriptionPackages) {
          this.subscriptionPackages = subscriptionPackages as SubscriptionPackageModel[];
        }
      });
  }

  ngOnDestroy() {
    this.subSink.unsubscribe();
  }

  areThereAnySubscriptionPackages(): boolean {
    if (this.subscriptionPackages) {
      return this.subscriptionPackages.length !== 0;
    }
    return true;
  }

}
