import {FooterData} from '../types/wordpress/layout/footer.type';
import {WpCommonFields} from '../types/wordpress/wp-common-fields.type';
import {WpImage} from '../types/wordpress/wp-image.type';

export class WordpressUtils {
  public static generateClassName(url: string): string {
    if (url) {
      const domainExtractRegex = /^(?:https?:\/\/)?(?:www\.)?([^.]+)/i;
      const regexMatchData = url.match(domainExtractRegex);

      return regexMatchData[regexMatchData.length - 1]; // Get last capture group
    }

    return '';
  }


  public static extractFooterData(commonData: WpCommonFields): FooterData {
    const {footer_links, sponsors} = commonData;

    return {
      footer_links,
      sponsors
    };
  }

  public static getImageData(image: WpImage): { src: string; srcset: string; } {
    return {
      src: image.sizes.medium_large,
      srcset: WordpressUtils.generateSrcSet(image)
    };
  }

  private static generateSrcSet(image: WpImage): string {
    const mediumImage = `${image.sizes.medium} ${image.sizes['medium-width']}w`;
    const mediumLargeImage = `${image.sizes.medium_large} ${image.sizes['medium_large-width']}w`;
    const largeImage = `${image.sizes.large} ${image.sizes['large-width']}w`;

    const originalImage = `${image.url} ${image.width}w`;

    return `${mediumImage}, ${mediumLargeImage}, ${largeImage}, ${originalImage}`;
  }
}
