import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafeHtmlPipe} from './pipe/safe-html.pipe';
import {LoaderComponent} from './loader/loader.component';
import {LabelPipe} from './pipe/label.pipe';
import {TextPipe} from './pipe/text.pipe';

@NgModule({
  declarations: [SafeHtmlPipe, LabelPipe, TextPipe, LoaderComponent],
  imports: [
    CommonModule
  ],
  exports: [
    SafeHtmlPipe,
    LabelPipe,
    TextPipe,
    LoaderComponent
  ]
})
export class SharedModule {
}
