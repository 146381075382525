import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UserState } from '../../shared/data/user-auth.model';
import { Button } from '../../shared/data/wordpress-data.model';
import { UserAuthService } from '../../user-auth.service';
import { Router } from '@angular/router';
import { CloudPayService } from '../../cloud-pay.service';

@Component({
  selector: 'amg-logout-btn',
  templateUrl: './logout-btn.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LogoutBtnComponent implements OnInit {
  @Input() logoutBtn: Button;
  show = false;

  constructor(private userAuthService: UserAuthService,
              private cloudPayService: CloudPayService,
              private router: Router) {
  }

  ngOnInit() {
    this.cloudPayService.cloudPayState()
      .subscribe((res) => {
        this.show = (res.isLoggedIn);
      });
  }

  public doLogout() {
    this.userAuthService
      .doLogOut();
    // .then((res) => {
    //   debugger;
    //   this.router.navigate(['/']);
    // });
  }

}
