<ng-template [ngIf]="isLoggedIn">
  <div class="account-control-dropdown">
    <div>
      {{'my-account.hi' | label}},
      <span [innerHTML]="customerName"></span>
      <ng-container *ngIf="customerProfilePicture?.length > 0; then thenBlock else elseBlock"></ng-container>
    </div>
    <div class="dropdown-items-container">
      <ul class="dropdown-items">
        <li>
          <a [href]="data.myProfile"
              target="_blank"
             class="btn">
            <ng-container *ngIf="customerProfilePicture?.length > 0; then thenBlock else elseBlock"></ng-container>
            <span class="name" [innerText]="customerFullName"></span>
            <span class="email" [innerText]="customerEmail"></span>
          </a>
        </li>
        <li>
          <a [href]="data.myProfile"
              target="_blank"
             [innerText]="'my-profile' | label"
             class="btn"></a>
        </li>
        <li>
          <a [href]="data.mySubscription"
             [innerText]="'my-subscription' | label"
             class="amg-translator-link btn"></a>
        </li>
        <li>
          <amg-logout-btn
            [logoutBtn]="{label: 'my-account.log-out'}">
          </amg-logout-btn>
        </li>
      </ul>
    </div>
  </div>
</ng-template>


<ng-template #thenBlock>
  <img class="profile-pic" [src]="customerProfilePicture" />
</ng-template>
<ng-template #elseBlock>
  <img class="profile-pic" />
</ng-template>
