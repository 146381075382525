import { Directive } from '@angular/core';

@Directive({
  selector: '[amgDropdown]'
})
export class DropdownDirective {

  constructor() { }

}
