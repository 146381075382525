// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleAnalyticsId: '',
  gigyaKey: '3_Rgx0QlX50EixfhIphy3oZS-_Nv7i5bxzi27UkptTpvdTzVkfJCKT9JoizmyCxiV9',
  gigyaProfile: 'https://testaccount.asroma.com',
  gigyaStorageDomainOverride: 'identity.devroma.streamamg.com',

  kaltura: {
    kalturaBaseUrl: 'https://open.http.mp.streamamg.com',
    partnerId: '3001192',
    uiConfId: '30024319'
  },

  wordpress: {
    api: 'https://wp-devroma.streamamg.com/wp-json/wpa/v1'
  },

  // base Url where the website is deployed, used for Payment integration template
  baseUrl: 'https://devroma.streamamg.com',
  cloudPayDomain: 'devromapayments.streamamg.com',
  cloudPay: {
    baseUrl: 'https://devromapayments.streamamg.com'
  }
};
