import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';

import { CloudPayState } from '@streamamg/cloud-pay-lib/src/lib/types/cloud-pay.type';
import { PaymentResponse, SubscriptionPlan } from '@streamamg/cloud-pay-lib/src/lib/types/cloud-pay-api.type';

import { Account } from './gigya-types/account';
import { TokenResponse } from './gigya-types/token-response';

import { ScriptTagService } from '@streamamg/amg-common';
import { CloudPayService } from './cloud-pay.service';

import { configs } from '../../core/configs';

declare var gigya;

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  private static gigyaLocalStorageId = 'GigyaSessionData';

  private user: BehaviorSubject<Account>;

  private script: BehaviorSubject<any>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private scriptTagService: ScriptTagService,
    private cloudPayService: CloudPayService, // to trigger and load the script, do not remove
  ) {
    this.script = new BehaviorSubject(null);
    this.loadScript();

    this.user = new BehaviorSubject<Account>(null);

    this.activatedRoute.queryParams
      .subscribe((params: Params) => {
        if (params.gig_actions) {
          document.body.parentElement.classList.add('hide-body');
        } else {
          document.body.parentElement.classList.remove('hide-body');
        }

      });

    this.cloudPayService.cloudPayState()
      .subscribe((cloudPayState: CloudPayState) => {

        if (!this.activatedRoute.snapshot.queryParams.gig_actions) {
          if (cloudPayState.isLoggedIn) {
            if (this.isPackagePurchaseInProgress()) {
              this.completePackagePurchase();
            }
          }
        }
      });
  }

  public isLoginInProgress(): boolean {
    return (!!this.activatedRoute.snapshot.queryParams.gig_actions);
  }

  public isPackagePurchaseInProgress(): boolean {
    return localStorage.getItem('purchaseUrl')?.length > 0;
  }

  public completePackagePurchase() {
    const encodedUrl = localStorage.getItem('purchaseUrl');
    if (encodedUrl?.length > 0) {
      document.body.classList.add('hidden');
      this.cloudPayService.cloudPayState()
        .subscribe((cloudPayState: CloudPayState) => {
          if (cloudPayState.isLoggedIn) {
            localStorage.removeItem('purchaseUrl');
            const purchaseUrl: string = atob(encodedUrl);
            location.href = purchaseUrl;
          }
        });
    }
  }

  public markPackageForPurchase(subscriptionPlan: SubscriptionPlan) {
    const purchaseUrl: string = subscriptionPlan.PurchaseUrl;
    const encodedUrl = btoa(purchaseUrl);
    localStorage.setItem('purchaseUrl', encodedUrl);
  }

  public doLogin(redirectTo?: string) {
    this.triggerScriptCall(() => {

      gigya.sso.login({
        authFlow: 'redirect',
        redirectURL: redirectTo || window.location.href,
        context: {
          source: window.location.host,
        },
      });

    });
  }

  public doLogOut() {
    this.triggerScriptCall(() => {

      this.cloudPayService.logOut();
      gigya.accounts.logout();

    });
  }

  private setUser(user?: Account) {
    if (user) {
      localStorage.setItem(UserAuthService.gigyaLocalStorageId, JSON.stringify(user));
    } else {
      localStorage.setItem(UserAuthService.gigyaLocalStorageId, '');
    }

    const oldUser = this.user.getValue();
    if (oldUser !== user) {
      this.user.next(user);
    }
  }

  public getUser(): Observable<Account> {
    return this.user.asObservable();
  }

  public refreshLogin(): Promise<void> {

    console.log('refreshLogin');

    return new Promise((resolve, reject) => {
      const user = this.user.getValue();

      if (user) {
        this.refreshJWT(user)
          .then(() => {
            resolve();
          })
          .catch((reason) => {
            reject(reason);
          });
      }
    });
  }

  private retrieveAccountAndRefreshJWT(): Promise<void> {

    console.log('retrieveAccountAndRefreshJWT');

    return new Promise<void>((resolve, reject) => {
      this.triggerScriptCall(() => {

        gigya.accounts
          .getAccountInfo({
            callback: (user: Account) => {
              switch (user.status) {
                case 'FAIL': {
                  // silently logout
                  // this.doLogOut();

                  // console.log('retrieveAccountAndRefreshJWT')
                  // this.cloudPayService.logOut();
                  reject('GIGYA-getAccountInfo-FAIL');
                  break;
                }
                case 'OK': {
                  this.refreshJWT(user)
                    .then(() => {
                      resolve();
                    })
                    .catch((reason) => {
                      reject(reason);
                    });
                  break;
                }
                default: {
                  console.log(`Unexpected status for getAccountInfo ${user.status}`);
                  reject('GIGYA-getAccountInfo-' + user.status);
                  break;
                }
              }
            },
          });
      });
    });
  }

  private refreshJWT(user: Account): Promise<void> {

    console.log('refreshJWT');

    this.setUser(user);

    return new Promise<void>((resolve, reject) => {
      this.triggerScriptCall(() => {
        gigya.accounts.getJWT({
          expiration: 24 * 60 * 60,
          fields: 'firstName,lastName,email',
          callback: (tokenResponse: TokenResponse) => {

            switch (tokenResponse.status) {
              case 'FAIL': {
                reject('GIGYA-getJWT-FAIL');
                break;
              }
              case 'OK': {
                this.cloudPayService.getSessionState()
                  .then((paymentResponse: PaymentResponse) => {
                    if (paymentResponse.CurrentCustomerSession === null) {
                      this.cloudPayService.loginJWT(tokenResponse.id_token)
                        .then(resolve)
                        .catch((err) => {
                          console.error('err', err);
                          reject('CLOUDPAY-loginJWT-error');
                        });
                    } else {
                      resolve();
                    }
                  });

                break;
              }
              default: {
                console.log(`Unexpected status for getJWT ${tokenResponse.status}`);
                reject('GIGYA-getJWT-' + tokenResponse.status);
                break;
              }
            }
          },
        });
      });
    });


  }

  private onLogin(user: Account) {
    this.refreshJWT(user)
      .then(() => {
        // nothing to do
      })
      .catch((err) => {
        // catch error
      });
  }

  private onLogout(res) {
    this.router.navigate(['/']);
  }

  private onError(res) {
    console.error(res);
  }

  private onConnectionAdded(res) {
    // debugger;
  }

  private onConnectionRemoved(res) {
    console.error(res);
  }

  public getAccountInfo() {

    console.log('getAccountInfo');

    this.triggerScriptCall(() => {

      gigya.accounts.getAccountInfo({
        callback: this.onLogin.bind(this),
      });

    });

    return this.getUser();
  }

  private triggerScriptCall(callback: () => void) {
    try {
      !!gigya;
      callback();
    } catch (ex) {
      this.waitUntilScriptsLoaded(callback);
    }
  }

  private waitUntilScriptsLoaded(callback: () => void) {
    const subscription = this.script.asObservable()
      .subscribe((script) => {
        if (script) {
          callback();
          subscription.unsubscribe();
        }
      });
  }

  private loadScript(): void {

    const subscription = this.scriptTagService
      .loadScript(configs.gigya.src, JSON.stringify(configs.gigya.jsConfigObject))
      .subscribe(
        scriptTagState => {
          if (scriptTagState.isLoaded) {
            this.handleScriptLoaded();
          } else if (scriptTagState.isError) {
            this.handleErrorStateWhenLoadingScript();
          }
        },
        error => {
          this.handleErrorStateWhenLoadingScript();
        },
        () => {
          subscription.unsubscribe();
        },
      );
  }

  private handleScriptLoaded() {
    this.script.next(gigya);

    if (window.location.hostname === 'localhost') {
      // overwrite gigya.sso.login for local development
      gigya.sso.login = () => {
        gigya.accounts.showScreenSet({ screenSet: 'Default-RegistrationLogin' });
      };
    }

    gigya.accounts
      .addEventHandlers({
        onLogin: this.onLogin.bind(this),
        onLogout: this.onLogout.bind(this),
        onError: this.onError.bind(this),
        onConnectionAdded: this.onConnectionAdded.bind(this),
        onConnectionRemoved: this.onConnectionAdded.bind(this),
      });
  }

  private handleErrorStateWhenLoadingScript() {
    debugger;
  }


}
