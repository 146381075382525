import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-cookie-notification',
  templateUrl: './cookie-notification.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookieNotificationComponent {
  private static readonly cookieNotificationPointer = 'roma';

  @HostBinding('attr.isConsentGiven') isUserConsentGiven;


  private static getUserConsent(): boolean {
    const userConsent = localStorage.getItem(CookieNotificationComponent.cookieNotificationPointer);
    return !!userConsent;
  }

  private static setUserConsent(userFlag: boolean): void {
    localStorage.setItem(CookieNotificationComponent.cookieNotificationPointer, JSON.stringify(userFlag));
  }

  constructor() {
    this.isUserConsentGiven = CookieNotificationComponent.getUserConsent();
  }

  setCookieConsent(): void {
    CookieNotificationComponent.setUserConsent(true);
    this.isUserConsentGiven = true;
  }
}
