import {ModuleWithProviders, NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import { FormBuilderComponent } from './shared/form-builder/form-builder.component';
import { TemplateBuilderComponent } from './shared/template-builder/template-builder.component';
import { LoginBtnComponent } from './sign-up-buttons/login-btn/login-btn.component';
import { LogoutBtnComponent } from './sign-up-buttons/logout-btn/logout-btn.component';
import { SubscriptionPackagesComponent } from './subscription-packages/subscription-packages.component';
import { SubscriptionPackageComponent } from './subscription-packages/subscription-package/subscription-package.component';
import { UserWelcomeComponent } from './user-welcome/user-welcome.component';
import { FormHeaderComponent } from './shared/template-builder/templates/form-header/form-header.component';
import { DropdownDirective } from './shared/_directives/dropdown.directive';
import { UserAccountControlComponent } from './user-account-control/user-account-control.component';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [
    FormBuilderComponent,
    TemplateBuilderComponent,
    LoginBtnComponent,
    LogoutBtnComponent,
    SubscriptionPackagesComponent,
    SubscriptionPackageComponent,
    UserWelcomeComponent,
    FormHeaderComponent,
    DropdownDirective,
    UserAccountControlComponent
  ],
  exports: [
    LoginBtnComponent,
    LogoutBtnComponent,
    FormBuilderComponent,
    TemplateBuilderComponent,
    SubscriptionPackagesComponent,
    SubscriptionPackageComponent,
    UserWelcomeComponent,
    UserAccountControlComponent
  ],
  providers: [
    CommonModule,
    FormBuilder
  ],
  entryComponents: [
    TemplateBuilderComponent
  ]
})
export class UserAuthModule {}
