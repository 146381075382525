import {Component, ViewEncapsulation, ChangeDetectionStrategy, Input} from '@angular/core';
import {FooterData} from '../../types/wordpress/layout/footer.type';
import {WordpressUtils} from '../../utils/wordpress-utils';
import {FooterSocialLink, FooterSocialGroup} from './footer-social-links.type';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  public defaultImage = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

  @Input() set footerData(value: FooterData) {

    this._footerData = value;
  }

  get footerData(): FooterData {
    return this._footerData;
  }

  public socialGroup: FooterSocialGroup[];
  public selectedSocialLinkId: number;
  private _footerData: FooterData;

  constructor() {
    this.selectedSocialLinkId = 0;
    this.socialGroup = [];
    this._footerData = null;
  }

  onChangeSocialCompetition(id: number) {
    this.selectedSocialLinkId = id;
  }

  getSelectedSocialGroupLinks(): FooterSocialLink[] {
    return this.socialGroup[this.selectedSocialLinkId].links;
  }
}
