import { Component, HostListener, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { DOCUMENT, Location, registerLocaleData } from '@angular/common';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { WINDOW } from './core/providers/window-provider';
import { WordpressService } from './core/services/wordpress.service';
import { FooterData } from './core/types/wordpress/layout/footer.type';
import { WordpressUtils } from './core/utils/wordpress-utils';
import { CookieService } from 'ngx-cookie-service';
import localeIt from '@angular/common/locales/it';
import { UserAuthService } from './modules/user-auth/user-auth.service';

registerLocaleData(localeIt);

declare const AMGTranslatorSwitch;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Roma TV+';
  public appReady = false;
  public wpFooterData: FooterData;

  private routerSubscription: Subscription;
  private commonFieldsWordpressSubscription: Subscription;

  private static setScreenSizesOnRoot(setWhat) {

    if (setWhat !== 'w') {
      const vh = window.innerHeight;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', vh + 'px');
    }
    // First we get the viewport height
    if (setWhat !== 'h') {
      const vw = document.body.clientWidth;
      document.documentElement.style.setProperty('--vw', vw + 'px');
    }
  }

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private wordpressService: WordpressService,
    private userAuthService: UserAuthService,
    private renderer: Renderer2,
    private location: Location,
    private cookieService: CookieService) {
    this.wpFooterData = null;
  }

  ngOnInit(): void {
    if (this.userAuthService.isLoginInProgress() || this.userAuthService.isPackagePurchaseInProgress()) {
      this.appReady = false;
    } else {

      AMGTranslatorSwitch();

      AppComponent.setScreenSizesOnRoot('both');

      let currentRoute = '';
      this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationStart || event instanceof NavigationEnd))
        .subscribe((event: NavigationStart | NavigationEnd) => {

          if (event instanceof NavigationStart) {
            if (currentRoute === event.url.split('/')[1]) {
              this.renderer.addClass(this.document.body.parentElement, 'same-page-navigation');
            } else {
              this.renderer.removeClass(this.document.body.parentElement, 'same-page-navigation');
            }
          } else {
            currentRoute = event.urlAfterRedirects.split('/')[1];
            this.setPageAttribute(event, 'urlAfterRedirects', 'data-page');
            this.sendGoogleAnalyticsData(event);

            this.setLangOnPath();
          }
        });

      this.commonFieldsWordpressSubscription = this.wordpressService.getCommonData()
        .subscribe(wpCommonData => {
          if (wpCommonData) {
            this.wpFooterData = WordpressUtils.extractFooterData(wpCommonData);
            this.appReady = true;
          }
        });
    }
  }

  setLangOnPath(): void {
    const lang = (window as any).appLang;

    if (lang) {
      const url = new URL(window.location.href);
      url.searchParams.set('lang', lang);
      // console.log(event.urlAfterRedirects);
      this.location.replaceState(url.pathname + url.search);
    }
  }

  setAppLang(): void {

    (window as any).appLang = 'en';
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.commonFieldsWordpressSubscription.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    AppComponent.setScreenSizesOnRoot('w');
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    setTimeout(() => {
      AppComponent.setScreenSizesOnRoot('h');
    }, 200);
  }

  private setPageAttribute(event: RouterEvent, eventUrlKey: string, htmlAttribute: string): void {
    if (event[eventUrlKey] !== '/') {
      const urlTree = this.router.parseUrl(event[eventUrlKey]);
      const page = urlTree.root.children.primary.segments.map(it => it.path).join('/');
      this.renderer.setAttribute(this.document.body.parentElement, htmlAttribute, page);
    }
  }

  private sendGoogleAnalyticsData(event: NavigationEnd): void {
    if (environment.googleAnalyticsId) {
      (this.window as any).ga('set', 'page', event.urlAfterRedirects);
      (this.window as any).ga('send', 'pageview');
    }
  }
}
