import {OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {Subscription} from 'rxjs';
import {Labels} from '../../../core/types/labels.type';
import {WordpressService} from '../../../core/services/wordpress.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
  name: 'text'
})
export class TextPipe implements PipeTransform, OnDestroy {
  private labelsSubscription: Subscription;
  private texts: Labels;

  constructor(private wordpressService: WordpressService, private sanitized: DomSanitizer) {

    this.texts = {};

    this.labelsSubscription = this.wordpressService.getTexts().subscribe(data => {

      if (data) {
        this.texts = data;
      }
    });
  }

  transform(value: string, ...args: any[]): SafeHtml {

    const text = this.texts[value] ? this.texts[value] : value;

    return this.sanitized.bypassSecurityTrustHtml(text);

  }

  ngOnDestroy(): void {
    this.labelsSubscription.unsubscribe();
  }
}
