import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { CloudPayModule } from '@streamamg/cloud-pay-lib/src/lib/cloud-pay.module';
import { CONFIG_TOKEN } from '@streamamg/cloud-pay-lib/src/lib/cloud-pay-tokens';
import { CloudPayConfiguration } from '@streamamg/cloud-pay-lib/src/lib/types/cloud-pay-configuration.type';
import { ScriptTagService } from '@streamamg/amg-common';
import { BehaviorSubject, Observable } from 'rxjs';
import { CloudPayState, CloudPayWindow } from '@streamamg/cloud-pay-lib/src/lib/types/cloud-pay.type';
import {
  CloudPayApi,
  CloudPayStatus,
  ContactSubmissionOptions,
  CustomerSessionData,
  LoginSubmissionForm,
  PaymentResponse,
  SubscriptionPlan,
  VoucherCodeValidResponse,
} from '@streamamg/cloud-pay-lib/src/lib/types/cloud-pay-api.type';

export const cloudPayConfs = {
  api: '/js/stream-payments.js',
  paths: {
    register: '/account/freeregistration',
    my_account: '/account',
    forgot_password: '/account/reset',
    buy_package: '/account/register/step2/',
  },
};

@Injectable({
  providedIn: CloudPayModule,
})
export class CloudPayService implements OnDestroy {
  private static readonly localStorageSessionDataKey = 'StreamPaymentSessionData';
  private cloudPayStateSubject: BehaviorSubject<CloudPayState>;

  public sessionData: PaymentResponse;
  private cpApi: CloudPayApi;

  private cloudPayInit: boolean = false;

  constructor(
    @Inject(CONFIG_TOKEN) private config: CloudPayConfiguration,
    private scriptTagService: ScriptTagService,
  ) {
    this.sessionData = this.getSessionData();
    this.cloudPayStateSubject = new BehaviorSubject<CloudPayState>({
      isLoggedIn: false,
      isEntitled: false,
      isScriptLoading: true,
    });

    this.loadCloudPayScript();
  }

  public cloudPayState(): Observable<CloudPayState> {
    return this.cloudPayStateSubject.asObservable();
  }

  public getKalturaSession(entryId: string): Observable<PaymentResponse> {
    const dataEmitter = new EventEmitter<PaymentResponse>();

    this.triggerStreamPaymentsCall(() => {
      this.cpApi.getKSession(entryId)
        .then((response) => {

          dataEmitter.emit(response);

        })
        .catch(err => {

          console.error('err', err);
          dataEmitter.emit({
            ErrorMessage: err,
            Status: CloudPayStatus.error,
          });

        })
        .finally(() => {

          dataEmitter.complete();
        });
    });

    return dataEmitter.asObservable();
  }

  public logOut(reloadPage?: boolean): void {

    console.log('logOut');

    this.triggerStreamPaymentsCall(() => {
      if (this.sessionData) {
        this.cpApi.doLogout()
          .then((response) => {

            if (reloadPage) {
              window.location.reload();
            }

          })
          .catch(err => {

            console.error('err', err);
          })
          .finally(() => {
            if (!reloadPage) {
              this.sessionData = null;
              this.cloudPayStateSubject.next({
                isEntitled: false,
                isLoggedIn: false,
              });
            }
          });
      }
    });
  }

  public getPackages(): Observable<SubscriptionPlan[]> {
    const dataEmitter = new EventEmitter<SubscriptionPlan[]>();

    this.triggerStreamPaymentsCall(() => {
      this.cpApi.getSubscriptionPackageList()
        .then((response) => {
          const subscriptions = response?.SubscriptionPlanOptions ? response.SubscriptionPlanOptions : [];
          dataEmitter.emit(subscriptions);
          dataEmitter.complete();
        })
        .catch(err => {
          console.error('err', err);
          dataEmitter.emit(null);
          dataEmitter.complete();
        });
    });

    return dataEmitter.asObservable();
  }

  /**
   * Logs into Cloud Pay using a JWT.
   */
  public loginJWT(jwtToken: string, redirect?: string): Promise<void> {

    console.log('loginJWT');

    return new Promise<void>((resolve, reject) => {
      this.triggerStreamPaymentsCall(() => {
        this.cpApi.init({
          language: this.config.language || 'en',
          jwtToken: jwtToken,
        })
          .then((response) => {
            console.log('init complete', response);

            this.cpApi.getSessionState()
              .then(response => {
                this.sessionData = this.getSessionData();
                this.setState(response.CurrentCustomerSession);

                const href = redirect || window.location.href.split('?')[0];

                const maybeCatch = this.cpApi.startSSO({ redirect: href });

                try {
                  maybeCatch.catch(reject);
                } catch (ex) {
                  // ignore;
                }

                resolve();
              })
              .catch(err => {
                console.error('err', err);
                reject(err);
              });
          })
          .catch(err => {
            console.error('err', err);
            reject(err);
          });
      });
    });
  }


  public login(data: LoginSubmissionForm, loginCallback?: () => void): Observable<PaymentResponse> {
    const dataEmitter = new EventEmitter<PaymentResponse>();

    this.triggerStreamPaymentsCall(() => {

      this.cpApi.doLogin(data, loginCallback ? loginCallback : null).then((response) => {
        console.log('Response', response);

        dataEmitter.emit(response);
        dataEmitter.complete();

        this.setState(response.CurrentCustomerSession);
        this.sessionData = this.getSessionData();
      })
        .catch(err => {
          console.error('err', err);
          dataEmitter.emit({ ModelErrors: err });
          dataEmitter.complete();
        });
    });

    return dataEmitter.asObservable();
  }

  public submitContactForm(form: ContactSubmissionOptions): Observable<PaymentResponse> {

    const dataEmitter = new EventEmitter<PaymentResponse>();

    this.triggerStreamPaymentsCall(() => {
      this.cpApi.doContactSubmission(form).then((response) => {
        console.log('Response', response);
        dataEmitter.emit(response);
        dataEmitter.complete();
      })
        .catch(err => {
          console.error('err', err);
          dataEmitter.emit({ ModelErrors: err });
          dataEmitter.complete();
        });
    });

    return dataEmitter.asObservable();
  }

  public isEmailAddressValid(data: { emailaddress: string }) {
    const dataEmitter = new EventEmitter<boolean>();

    this.triggerStreamPaymentsCall(() => {
      this.cpApi.isEmailAddressValid(data).then((response) => {
        console.log('Response', response);
        dataEmitter.emit(response);
        dataEmitter.complete();
      })
        .catch(err => {
          console.error('err', err);
          dataEmitter.emit(false);
          dataEmitter.complete();
        });
    });

    return dataEmitter.asObservable();
  }

  public isEmailAddressRegistered(data: { emailaddress: string }) {
    const dataEmitter = new EventEmitter<boolean>();

    this.triggerStreamPaymentsCall(() => {
      this.cpApi.isEmailAddressRegistered(data).then((response) => {
        console.log('Response', response);
        dataEmitter.emit(response);
        dataEmitter.complete();
      })
        .catch(err => {
          console.error('err', err);
          dataEmitter.emit(false);
          dataEmitter.complete();
        });
    });

    return dataEmitter.asObservable();
  }

  public isVoucherCodeValid(data: { code: string }): Observable<VoucherCodeValidResponse> {
    const dataEmitter = new EventEmitter<VoucherCodeValidResponse>();

    this.triggerStreamPaymentsCall(() => {
      this.cpApi.isVoucherCodeValid(data).then((response) => {
        console.log('Response', response);
        dataEmitter.emit(response);
        dataEmitter.complete();
      })
        .catch(err => {
          console.error('err', err);
          dataEmitter.emit({ ModelErrors: err });
          dataEmitter.complete();
        });
    });

    return dataEmitter.asObservable();
  }

  private initializeStreamPayments(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.triggerStreamPaymentsCall(() => {
        this.cpApi.init({
          language: this.config.language || 'en', ...(this.config.isSso ? { jwtToken: this.getJwtToken() } : {}),
        })
          .then((response) => {
            if (!this.cloudPayInit) {
              console.log('cloudpay initialised');
            } else if (!this.config.isSso) {
              console.log('cloudpay reinitalised');
            } else {
              console.log('cloudpay token updated');
            }
            this.cloudPayInit = true;
            resolve(true);
          })
          .catch(err => {
            console.error('err', err);
            reject(err);
          });
      });
    });
  }

  private triggerStreamPaymentsCall(callback: () => void) {
    if (this.cpApi) {
      callback();
    } else {
      this.waitUntilScriptsLoaded(callback);
    }
  }

  /**
   * Internal function used to wait until all scripts have been loaded before executing API call.
   * @param callback The function to execute once loaded.
   */
  private waitUntilScriptsLoaded(callback: () => void) {
    const subscription = this.cloudPayState().subscribe(state => {
      if (!state.isScriptLoading) {
        callback();
        subscription.unsubscribe();
      }
    });
  }

  private setState(customerSessionData: CustomerSessionData): void {
    this.cloudPayStateSubject.next({
      isEntitled: (customerSessionData !== null && customerSessionData?.CustomerEntitlements !== null && customerSessionData?.CustomerEntitlements !== ''),
      isLoggedIn: (customerSessionData !== null),
    });
  }

  /**
   * When script is loaded, trigger initialization of Stream Payments based on config.
   */

  private handleScriptLoaded() {
    this.cpApi = (window as CloudPayWindow).streamPayments;

    this.initializeStreamPayments();

    if (this.sessionData) {
      this.cpApi.getSessionState()
        .then((response) => {
          this.setState(response.CurrentCustomerSession);
        })
        .catch(err => {
          console.error('err', err);
          this.cloudPayStateSubject.next({
            isLoggedIn: false,
            isEntitled: false,
          });
        });
    } else {

      this.cloudPayStateSubject.next({
        isLoggedIn: false,
        isEntitled: false,
      });
    }
  }

  /**
   * Handles error when loading script.
   */
  private handleErrorStateWhenLoadingScript(): void {
    this.cloudPayStateSubject.next({
      isScriptLoading: false,
      scriptError: true,
      isLoggedIn: false,
      isEntitled: false,
    });
  }

  public getSessionState(): Promise<PaymentResponse> {
    return new Promise((resolve, reject) => {
      this.triggerStreamPaymentsCall(() => {
        this.cpApi
          .getSessionState()
          .then((paymentResponse: PaymentResponse) => {
            resolve(paymentResponse);
          })
          .catch((reason) => {
            reject(reason);
          });
      });
    });
  }


  /**
   * Sets the JWT token in local storage and reinitialise Cloud Pay.
   */

  /* private setJwtToken(jwtToken: string): Promise<boolean> {
     return new Promise((resolve, reject) => {
       this.setSessionData({
         JwtTokenCacheKey: jwtToken,
       })
         .then(() => {
           this.initializeStreamPayments()
             .then(() => {
               resolve(true);
             })
             .catch((res) => {
               reject('Problem reinitialising Stream Payments with new token');
             });
         })
         .catch((res) => {
           reject('Problem setting new JWT');
         });
     });

   }*/

  /**
   * Retrieves the JWT token from local storage based.
   */
  private getJwtToken(): string {
    const sessionData = this.getSessionData();

    if (sessionData?.JwtTokenCacheKey) {
      return sessionData.JwtTokenCacheKey;
    }

    return null;
  }

  /**
   * Appends to the session data in local storage or creates new session data if not present.
   */

  /*private setSessionData(data: { [s: string]: string }): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        if (window.localStorage) {
          let sessionDataString = window.localStorage.getItem(CloudPayService.localStorageSessionDataKey);

          let sessionData = JSON.parse(sessionDataString) || {};
          sessionData = {
            ...sessionData,
            ...data,
          };

          window.localStorage.setItem(CloudPayService.localStorageSessionDataKey, JSON.stringify(sessionData));

          resolve();
        }
      } catch (e) {
        reject(e);
      }
    });
  }*/

  /**
   * Retrieves the session data from local storage if present.
   */
  private getSessionData(): PaymentResponse | null {
    try {
      if (window.localStorage) {
        const sessionDataString = window.localStorage.getItem(CloudPayService.localStorageSessionDataKey);
        return JSON.parse(sessionDataString);
      }
    } catch (e) {
    }

    return null;
  }

  /**
   * Triggers the loading of stream payments client-side API.
   */
  private loadCloudPayScript(): void {

    const subscription = this.scriptTagService.loadScript(this.config.url + cloudPayConfs.api).subscribe(
      scriptTagState => {
        if (scriptTagState.isLoaded) {
          this.handleScriptLoaded();
        } else if (scriptTagState.isError) {
          this.handleErrorStateWhenLoadingScript();
        }
      },
      error => {
        this.handleErrorStateWhenLoadingScript();
      },
      () => {
        subscription.unsubscribe();
      },
    );
  }

  public ngOnDestroy(): void {
    this.cloudPayStateSubject.complete();
  }
}

