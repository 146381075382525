import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

const routes: Routes = [
  {
    path: 'on-demand',
    loadChildren: () => import('./pages/video/video.module')
      .then(m => m.VideoModule)
  },
  {
    path: 'tv',
    loadChildren: () => import('./pages/video/video.module')
      .then(m => m.VideoModule)
  },
  {
    path: 'live',
    loadChildren: () => import('./pages/video/video.module')
      .then(m => m.VideoModule)
  },
  {
    path: 'archive',
    loadChildren: () => import('./pages/archive/archive.module')
      .then(m => m.ArchiveModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/support/support.module')
      .then(m => m.SupportModule)
  },
  {
    path: 'subscribe',
    loadChildren: () => import('./pages/subscribe/subscribe.module')
      .then(m => m.SubscribeModule)
  },
  {
    path: '**',
    redirectTo: 'on-demand',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
