<ng-container *ngIf="data && data.isUserLoggedIn">
  <span class="amg-welcome-text"
        [innerHTML]="welcomeText"></span>
  <span class="amg-full-name">
    <span class="amg-name"
        [innerHTML]="data.name"></span>
    <span class="amg-last-name"
        [innerHTML]="data.lastName"></span>
  </span>
</ng-container>
